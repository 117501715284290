import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "month-paginator",
      "style": {
        "position": "relative"
      }
    }}>{`Month Paginator`}</h1>
    <p>{`The Month Paginator allows the user to navigate months and years.`}</p>
    <h2 {...{
      "id": "best-practices",
      "style": {
        "position": "relative"
      }
    }}>{`Best practices`}</h2>
    <ul>
      <li parentName="ul">{`Use the month paginator component to control the navigation through month paged information.`}</li>
      <li parentName="ul">{`If the user should select a date, use the `}<a parentName="li" {...{
          "href": "/components/date-picker"
        }}>{`Date Picker`}</a>{` component.`}</li>
    </ul>
    <h2 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}>{`Example`}</h2>
    <Demo src='pages/components/month-paginator/FinancialMonthPaginator' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      